import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Tweet } from 'react-twitter-widgets'

const TweetWidget = ( props ) => {
  const tempData  = useStaticQuery(graphql`
    query TweetWidgetQuery {
      allContentfulTweets {
        edges {
          node {
            tweetIdMap {
              url
              tweetId
            }
          }
        }
      }
    }
  `)
  const tweetIdMap = tempData.allContentfulTweets.edges[0].node.tweetIdMap;
	const tweet = tweetIdMap.find(data => data.url === props.urlPath);
	const tweetId = tweet ? tweet.tweetId : null;

  return (
    <>
    {/* {tweetId && ( */}
    {false && (
      <>
        <div className="column has-text-centered" style={{paddingTop: `0.85rem`, paddingBottom: `0.6rem`}}>
          <p style={{fontSize:`0.7rem`}}><strong>＼ X(旧Twitter) <a href="https://x.com/hula_la_" style={{color: `#1d7fdb`}}>オムツ1枚あたりの最安値(@hula_la_)</a> ／</strong></p>
        </div>
        <div className="columns is-mobile">
          <div className="column" style={{paddingTop: `0.0rem`,paddingBottom: `0.0rem`, paddingLeft: `5.0rem`, paddingRight: `5.0rem`}}>
            <Tweet tweetId={`${tweetId}`} options={{ lang: "ja", width: "auto" }} />
          </div>
        </div>
      </>
    )}
    </>
    );
};

export default TweetWidget;
