import React from "react";
import {
  FacebookShareButton,
  FacebookIcon, 
  LineShareButton, 
  LineIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";

const Share = ({ articleUrl, articleTitle }) => {
  return (
    <div id="share" className="column has-text-centered">
      <br/>
      <p style={{fontSize:`0.7rem`}}><strong>＼ {articleTitle}の最安値 ／</strong></p>
      <br/>
      <TwitterShareButton url={articleUrl} title={`${articleTitle}の最安値`} via="">
        <XIcon size={32} round />
      </TwitterShareButton>
      &nbsp; &nbsp; 
      <FacebookShareButton url={articleUrl} quote={`${articleTitle}の最安値`}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      &nbsp; &nbsp; 
      <LineShareButton url={articleUrl} title={`${articleTitle}の最安値`}>
        <LineIcon size={32} round />
      </LineShareButton>
    </div>
  )
}

export default Share